import React, { useState, useEffect, useCallback } from 'react';
import { Button, Icon, Loader, Message, Modal, Step, Transition } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import DropFile from './DropFile';
import LanguageCombination from './LanguageCombination';
import StageSelect from './StageSelect';
import GroupSelect from './GroupSelect';
import ErrorModal from 'errors/components/ErrorModal';
import Summary from './Summary';
import { languagesDictionary, ocrLangs } from 'dictionaries/dictionaries';
import { sponsors } from 'dictionaries/sponsors';
import { createJobTemplate, getJobTemplate } from 'api-operations/requester/template';
import { createJob } from 'api-operations/requester/job';
import { useLogger } from 'logger/Logger';
import { getMyGatedComunities } from 'api-operations/community/member';
import { hasErrorCode } from 'errors/error-guards';
import Taxonomy from './Taxonomy';
import { isAnonymizationEnabled } from 'api-operations/requester/common';
import { uploadFile } from 'api-operations/expiringFile';
import UserService from 'UserService';
import { langMissmatch } from './droparea/FileUploadItem';
const SubmitButtonContainer = styled.div `
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;
const Loading = styled(Loader) `
  &&&& {
    &:after {
      border-color: #767676 #ddd #ddd;
    }
  }
`;
const InfoMessage = styled.span `
  font-size: 1.4rem;
  font-weight: 600;
  margin-right: 1em;
`;
const steps = ["file", "langs", "taxonomy", "stages", "groups", "summary"];
const hide = 0;
const show = 500;
const defaultEnabledStages = process.env.ENVIRONMENT == 'prod-main' ? new Set(['RECYCLING']) : new Set(['RECYCLING', 'FINAL_REDACTION']);
const NewUpload = () => {
    const [files, setFiles] = useState([]);
    const [attachmentFiles, setAttachmentFiles] = useState([]);
    const [genre, setGenre] = useState('');
    const [languageFrom, setLanguageFrom] = useState(undefined);
    const [languagesTo, setLanguagesTo] = useState([]);
    const [taxonomy, setTaxonomy] = useState(null);
    const [stages, setStages] = useState(defaultEnabledStages);
    const [manualStages, setManualStages] = useState(new Set(['REDACTION', 'ENHANCEMENT', 'TRUST_MINING', 'TRUST_MINING_2']));
    const [communities, setCommunities] = useState([]);
    const [isOpenCommunity, setIsOpenCommunity] = useState(false);
    const [myGatedCommunities, setMyGatedCommunities] = useState([]);
    const [externalProjectId, setExternalProjectId] = useState(null);
    const [projectCode, setProjectCode] = useState(null);
    const [protocolNumber, setProtocolNumber] = useState(null);
    const [requesterNote, setRequesterNote] = useState(null);
    const [testMode, setTestMode] = useState(false);
    const [activePhase, setActivePhase] = useState(0);
    const [isUploading, setisUploading] = useState(false);
    const [touchedSteps, setTouchedSteps] = useState(new Set(['file']));
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [jobTemplateId, setJobTemplateId] = useState('');
    const [csvFile, setCsvFile] = useState(null);
    const [jobName, setJobName] = useState(null);
    const [externalDeadline, setExternalDeadline] = useState(null);
    const [uploadedJobs, setUploadedJobs] = useState(0);
    const [isProperCsv, setIsProperCsv] = useState(null);
    const [isExternalDateIncorrect, setIsExternalDateIncorrect] = useState(false);
    const [isLanguageMissmatch, setIsLanguageMissmatch] = useState(false);
    const [isAnonEnabled, setIsAnonyEnabled] = useState(false);
    const [errorWindowMessage, setErrorWindowMessage] = useState(null); //TODO - remove it? setErrorWindowMessage is not used.
    const { t } = useTranslation('common');
    const history = useHistory();
    const company = useSelector(state => state.userProfile.company);
    const { logger } = useLogger();
    const dateFormats = ["YYYY-MM-DD h:mm:ss Z", "YYYY-MM-DD h:mm:ss", "YYYY/MM/DD h:mm:ss Z", "YYYY/MM/DD h:mm:ss"];
    useEffect(() => {
        setIsError(false);
    }, [files, genre, languageFrom, languagesTo, taxonomy, stages, communities]);
    useEffect(() => {
        if (jobTemplateId !== '') {
            (async () => {
                const res = await getJobTemplate(jobTemplateId);
                const jobTemplate = res;
                const { gatedCommunities, genre, langFrom, langTo, langsTo, openCommunity, taxonomy, stages, manualStages } = jobTemplate.template;
                setCommunities(gatedCommunities);
                setGenre(genre);
                setLanguageFrom(langFrom);
                setLanguagesTo(langsTo || [langTo]);
                setIsOpenCommunity(openCommunity);
                setTaxonomy(taxonomy);
                setStages(new Set(stages));
                setManualStages(new Set(manualStages));
                logger.info('User - Selected template');
            })();
        }
    }, [jobTemplateId]);
    useEffect(() => {
        getMyGatedCommunitiesList();
        getAnonymizationStatus();
    }, []);
    const getMyGatedCommunitiesList = async () => {
        try {
            const myGCommunities = await getMyGatedComunities(true);
            setMyGatedCommunities(myGCommunities);
        }
        catch (error) {
            //
        }
    };
    const getAnonymizationStatus = async () => {
        try {
            const response = await isAnonymizationEnabled();
            setIsAnonyEnabled(response);
        }
        catch (error) {
            //
        }
    };
    const processCSV = (csvFile) => {
        setCsvFile(csvFile);
        if (!csvFile) {
            setIsError(false);
            return;
        }
        const fileReader = new FileReader();
        fileReader.onload = function () {
            const result = csvJSON(fileReader.result);
            if (!result)
                return;
            uploadCsv(csvFile).then(uploadedCsv => {
                logger.info(`User - Uploaded csv (fileId = ${uploadedCsv.id}) deadline: ${result.deadline}, external project id: ${result.id}`);
            });
        };
        fileReader.readAsText(csvFile);
    };
    const csvJSON = (csv) => {
        const lines = csv.split('\n');
        const startLine = lines.findIndex(line => line.includes('\t'));
        const headers = lines[startLine].toLowerCase().replaceAll(' ', '').split('\t');
        const result = Object.fromEntries(lines[startLine + 1].split('\t').map((value, index) => ([normalizeValuesFromCsv(headers[index]), normalizeValuesFromCsv(value)])));
        if (!(result['customfields>projectcode'] && result.id && result.name && result.service && result.languages && result.deadline)) {
            setIsProperCsv(false);
            return;
        }
        setIsProperCsv(true);
        setManualStages(new Set());
        setGenre('life_sciences');
        if (result.specialization) {
            setTaxonomy(`IQVIA:${result.specialization}`);
        }
        else {
            setTaxonomy('Category:Clinical trials');
        }
        let tmp = null;
        let sponsor = null;
        // eslint-disable-next-line no-cond-assign
        if (tmp = myGatedCommunities.find((community) => community.gatedCommunityName === 'IQVIA Translations')) {
            setCommunities(prevState => [...prevState, { id: tmp.gatedCommunityId, name: tmp.gatedCommunityName }]);
        }
        // eslint-disable-next-line no-cond-assign
        if (sponsor = sponsors.find(({ projectId }) => projectId == result['customfields>projectcode'])) {
            // eslint-disable-next-line no-cond-assign
            if (tmp = myGatedCommunities.find((community) => community.gatedCommunityName.toLowerCase() === sponsor.sponsor.toLowerCase())) {
                setCommunities(prevState => [...prevState, { id: tmp.gatedCommunityId, name: tmp.gatedCommunityName }]);
            }
        }
        setProjectCode(result['customfields>projectcode']);
        setProtocolNumber(result['customfields>protocolnumber']);
        setJobName(result.name);
        setExternalDeadline(result.deadline);
        setExternalProjectId(result.id);
        scrapeJobStagesFromCsv(result.service);
        scrapeLanguagesFromCsv(result.languages);
        return result;
    };
    const uploadCsv = async (file) => {
        return await uploadFile(file, () => { });
    };
    const getTimezone = (deadline) => {
        const zoneAbbrFromCsv = deadline.split(" ").pop();
        const userTimezone = UserService.getTokenUserInfo().timezone;
        if (moment.tz(userTimezone).zoneAbbr() == zoneAbbrFromCsv)
            return moment.tz(userTimezone).format("Z");
        else {
            const firstMatchingZone = moment.tz.names().find(n => moment.tz(n).format("z") == zoneAbbrFromCsv);
            return moment.tz(firstMatchingZone || userTimezone).format("Z");
        }
    };
    const normalizeValuesFromCsv = (value) => value.replaceAll('\r', '').replaceAll('"', '');
    const scrapeJobStagesFromCsv = (stagesString) => {
        if (!stagesString)
            return;
        const tempStagesString = stagesString.toLowerCase();
        if (tempStagesString.includes("1 step verification")) {
            setStages(new Set(['RECYCLING', 'ENHANCEMENT', 'TRUST_MINING', 'FINAL_REDACTION']));
            return;
        }
        else if (tempStagesString.includes("2 step verification")) {
            setStages(new Set(['RECYCLING', 'ENHANCEMENT', 'TRUST_MINING', 'TRUST_MINING_2', 'FINAL_REDACTION']));
            return;
        }
        if (tempStagesString.includes("translation")) {
            setStages(new Set(['RECYCLING', 'ENHANCEMENT', 'FINAL_REDACTION']));
        }
    };
    const scrapeLanguagesFromCsv = (languagesString) => {
        if (!languagesString)
            return;
        const langs = languagesString.toLowerCase().replaceAll('-', '_').split(',');
        const tempLangs = langs.map((element) => {
            const langs = element.split('»');
            const langFrom = langs[0].trim() == 'es_es' ? 'es' : langs[0].trim() == 'es_ar' ? 'es_419' : langs[0].trim();
            const langTo = langs[1].trim() == 'es_es' ? 'es' : langs[1].trim() == 'es_ar' ? 'es_419' : langs[1].trim();
            return { langFrom, langTo };
        });
        setLanguageFrom(() => languagesDictionary.includes(tempLangs[0].langFrom) ? tempLangs[0].langFrom : undefined);
        setLanguagesTo(() => tempLangs.map(({ langTo }) => langTo).filter(langTo => languagesDictionary.includes(langTo)));
    };
    const getValuesFromTemplate = (genre, languageFrom, languagesTo, taxonomy, stages, manualStages, communities, isOpenCommunity) => {
        setGenre(genre);
        setLanguageFrom(languageFrom);
        setLanguagesTo(languagesTo);
        setTaxonomy(taxonomy);
        setStages(stages);
        setManualStages(manualStages);
        setCommunities(communities);
        setIsOpenCommunity(isOpenCommunity);
    };
    const handleRemoveFile = useCallback(async (fileId) => {
        setFiles(prevState => prevState.filter((uploadedFile) => uploadedFile.id !== fileId));
    }, [files, setFiles]);
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return React.createElement(DropFile, { setGenre: setGenre, files: files, setFiles: setFiles, setJobTemplateId: setJobTemplateId, jobTemplateId: jobTemplateId, genre: genre, setCsvFile: processCSV, csvFile: csvFile, languageFrom: languageFrom });
            case 1:
                return React.createElement(LanguageCombination, { setLanguageFrom: setLanguageFrom, langFrom: languageFrom, setLanguagesTo: setLanguagesTo, langsTo: languagesTo });
            case 2:
                return React.createElement(Taxonomy, { setTaxonomy: setTaxonomy, taxonomy: taxonomy });
            case 3:
                return React.createElement(StageSelect, { setStages: setStages, stages: stages, setManualStages: setManualStages, manualStages: manualStages, isAnonEnabled: isAnonEnabled, sourceLang: languageFrom });
            case 4:
                return React.createElement(GroupSelect, { setCommunities: setCommunities, setIsOpenCommunity: setIsOpenCommunity, isOpenCommunity: isOpenCommunity, communities: communities });
            case 5:
                return React.createElement(Summary, { setExternalProjectId: setExternalProjectId, setRequesterNote: setRequesterNote, externalProjectId: externalProjectId, requesterNote: requesterNote, setTestMode: setTestMode, testMode: testMode, genre: genre, languageFrom: languageFrom, languagesTo: languagesTo, taxonomy: taxonomy, stages: stages, manualStages: manualStages, communities: communities, isOpenCommunity: isOpenCommunity, createJobTemplate: onCreateJobTemplate, getValuesFromTemplate: getValuesFromTemplate, edit: !!csvFile, files: files, handleRemoveFile: handleRemoveFile, ngo: company.ngo, jobName: jobName, setJobName: setJobName, attachmentFiles: attachmentFiles, setAttachmentFiles: setAttachmentFiles });
            default:
                logger.info('User - Entered unknown step');
                return React.createElement("p", null, "Unknown step");
        }
    };
    const changeTab = (oldTab, newTab) => {
        const err = verifyIfFilled(steps[oldTab]);
        if (err.error) {
            setIsError(true);
            setErrorMsg(err.msg);
            return;
        }
        setActivePhase(newTab);
    };
    const moveForward = () => {
        if (activePhase == 0 && csvFile) {
            if (files.some(({ name }) => isFileHasExtension(name, 'pdf'))) {
                setStages((prevState) => {
                    const temp = new Set(prevState);
                    temp.add('REDACTION');
                    return temp;
                });
            }
        }
        const err = verifyIfFilled(steps[activePhase]);
        if (err.error) {
            setIsError(true);
            setErrorMsg(err.msg);
            return;
        }
        if ((jobTemplateId !== '' || csvFile) && activePhase === 0) {
            setActivePhase(steps.length - 1);
            const temp = touchedSteps;
            temp.add(steps[activePhase + 1]);
            for (let index = 1; index < steps.length - 1; index++) {
                temp.add(steps[index + 1]);
            }
            setTouchedSteps(temp);
            return;
        }
        const temp = touchedSteps;
        temp.add(steps[activePhase + 1]);
        setActivePhase(prevState => prevState + 1);
        setTouchedSteps(temp);
    };
    const moveBack = () => {
        if (activePhase === 0)
            return;
        setActivePhase(prevState => prevState - 1);
    };
    const isFileHasExtension = (name, extension) => {
        return name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase() == extension || false;
    };
    // eslint-disable-next-line complexity
    const verifyIfFilled = (uploadStep) => {
        switch (uploadStep) {
            case 'file':
                if (!files.length) {
                    return { error: true, msg: t('jobUpload.errors.noFile') };
                }
                if (!genre && (!csvFile || activePhase === 6)) {
                    return { error: true, msg: t('jobUpload.errors.noGenre') };
                }
                if (csvFile && isProperCsv === false) {
                    return { error: true, msg: t('jobUpload.errors.wrongCsv') };
                }
                break;
            case 'langs':
                if (languageFrom === '' || languageFrom === undefined || languagesTo.length === 0 || languagesTo.includes(languageFrom)) {
                    return { error: true, msg: t('jobUpload.errors.noLanguages') };
                }
                break;
            case 'taxonomy':
                if (taxonomy === null || taxonomy === undefined || taxonomy === '') {
                    return { error: true, msg: t('jobUpload.errors.noTaxonomy') };
                }
                break;
            case 'stages':
                if (stages.size === 0) {
                    return { error: true, msg: t('jobUpload.errors.noStages') };
                }
                if (files.some(({ name }) => isFileHasExtension(name, 'pdf'))) {
                    if (!ocrLangs.includes(languageFrom)) {
                        return { error: true, msg: t('jobUpload.errors.noSupportedLang', { lang: t(`languages.${languageFrom}`) }) };
                    }
                }
                break;
            case 'groups':
                if (!isOpenCommunity && communities.length == 0) {
                    return { error: true, msg: t('jobUpload.errors.noGroups') };
                }
                break;
        }
        return { error: false, msg: null };
    };
    const toNewStages = (stages, manualStages) => {
        const newStages = [];
        const executorSelection = (stage) => manualStages.has(stage) ? 'manual' : 'auto';
        newStages.push({ stage: 'FILE_PREPARATION' });
        newStages.push({ stage: 'FILE_PARSING' });
        stages.has('ANONYMIZATION') && newStages.push({ stage: 'ANONYMIZATION' });
        stages.has('REDACTION') && newStages.push({ stage: 'REDACTION', executorSelection: executorSelection('REDACTION') });
        stages.has('RECYCLING') && newStages.push({ stage: 'RECYCLING' });
        if (stages.has('ENHANCEMENT')) {
            newStages.push({ stage: 'ENHANCEMENT', executorSelection: executorSelection('ENHANCEMENT') });
        }
        if (stages.has('TRUST_MINING')) {
            newStages.push({ stage: 'TRUST_MINING', executorSelection: executorSelection('TRUST_MINING') });
        }
        if (stages.has('TRUST_MINING_2')) {
            newStages.push({ stage: 'TRUST_MINING', executorSelection: executorSelection('TRUST_MINING_2') });
        }
        if (stages.has('FINAL_REDACTION')) {
            newStages.push({ stage: 'FINAL_REDACTION', executorSelection: executorSelection('FINAL_REDACTION') });
        }
        return newStages;
    };
    const createNewJob = async (externalDeadline, ignoreLanguageMissmatch = false) => {
        const steps = ['langs', 'file', 'taxonomy', 'stages', 'groups'];
        for (const step of steps) {
            const err = verifyIfFilled(step);
            if (err.error) {
                setIsError(true);
                setErrorMsg(err.msg);
                return;
            }
        }
        if (!jobName) {
            setIsError(true);
            setErrorMsg(t('jobUpload.errors.noJobName'));
            return;
        }
        if (externalDeadline) {
            const deadline = moment.parseZone(externalDeadline, dateFormats);
            if (!deadline.isValid()) {
                setIsExternalDateIncorrect(true);
                return;
            }
            if (deadline.isBefore()) {
                setIsExternalDateIncorrect(true);
                return;
            }
        }
        if (files.filter(f => langMissmatch(languageFrom, f.detectedLanguages)).length > 0 && !ignoreLanguageMissmatch) {
            setIsLanguageMissmatch(true);
            return;
        }
        setisUploading(true);
        const newStages = toNewStages(stages, manualStages);
        if (!languageFrom)
            return;
        if (!taxonomy)
            return;
        for (let i = 0; i < languagesTo.length; i++) {
            const job = {
                inputFiles: files.map(({ id }) => id),
                attachmentFiles: attachmentFiles.map(({ id }) => id),
                langFrom: languageFrom,
                langTo: languagesTo[i],
                taxonomy: taxonomy.split(', '),
                genre,
                externalDeadline: externalDeadline ? `${moment.parseZone(externalDeadline, dateFormats).format("yyyy-MM-DD HH:mm")} ${getTimezone(externalDeadline)}` : undefined,
                stages: newStages,
                requesterNote: requesterNote ? requesterNote : undefined,
                type: (company === null || company === void 0 ? void 0 : company.ngo) ? 'CHARITY' : testMode ? 'EXAMINATION' : 'COMMERCIAL',
                name: jobName,
                projectCode: projectCode ? projectCode : undefined,
                protocolNumber: protocolNumber ? protocolNumber : undefined,
                externalProjectId: externalProjectId ? externalProjectId : undefined,
                tableParsingConfig: files.some(({ name }) => isFileHasExtension(name, 'csv')) ? [{ columnId: 1, readingMode: "EXISTING_COLUMN", targetColumn: 3 }] : undefined,
                visibility: {
                    open: isOpenCommunity,
                    communities: Array.from(new Set(communities.map(({ id }) => id)))
                }
            };
            try {
                await createJob(job);
                logger.info(`User - Uploaded job, input files count: ${job.inputFiles.length}`);
            }
            catch (error) {
                toast.error(t('notification.error'));
                logger.error('User - Failed to upload job', error);
            }
            setUploadedJobs(prevState => prevState + 1);
        }
        setTimeout(function () {
            history.push('/requester');
        }, 500);
    };
    const onCreateJobTemplate = async (name) => {
        const template = {
            name: name,
            template: {
                genre: genre,
                langFrom: languageFrom,
                langsTo: languagesTo,
                openCommunity: isOpenCommunity,
                companies: [],
                gatedCommunities: communities,
                taxonomy: taxonomy,
                stages: Array.from(stages),
                manualStages: Array.from(manualStages)
            }
        };
        try {
            await createJobTemplate(template);
            toast.success(t('notification.templateCreated'));
            logger.info('User - Created template from summary');
            return true;
        }
        catch (error) {
            if (hasErrorCode(409, error)) {
                toast.error(t('notification.templateExists'));
            }
            else {
                toast.error(t('notification.error'));
            }
            logger.error('User - Failed to create template from summary', error);
            return false;
        }
    };
    if (errorWindowMessage !== null)
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    return React.createElement("div", null,
        React.createElement(Step.Group, { size: 'mini', fluid: true }, steps.map((step, index) => React.createElement(Step, { link: activePhase >= index, key: step, active: activePhase === index, disabled: !touchedSteps.has(step), onClick: () => changeTab(activePhase, index) },
            React.createElement(Step.Content, null,
                React.createElement(Step.Title, null, t(`jobUpload.steps.${step}`)),
                React.createElement(Step.Description, null, t(`jobUpload.steps.${step}Desc`)))))),
        React.createElement(Transition, { animation: 'fade down', duration: { hide, show } },
            React.createElement("div", null,
                getStepContent(activePhase),
                isError && !(csvFile && isProperCsv === false) && React.createElement(Message, { negative: true },
                    React.createElement("p", null, errorMsg)),
                csvFile && isProperCsv === false && React.createElement(Message, { negative: true },
                    React.createElement("p", null, t('jobUpload.errors.wrongCsv'))),
                React.createElement(SubmitButtonContainer, null,
                    activePhase !== 0 && React.createElement(Button, { onClick: () => moveBack() }, t('jobUpload.back')),
                    activePhase !== steps.length - 1 ? React.createElement(Button, { primary: true, onClick: () => moveForward() }, t('jobUpload.next'))
                        : React.createElement(Button, { primary: true, disabled: files.filter(f => f.typedValidationError).length > 0, onClick: () => createNewJob(externalDeadline) }, t('jobUpload.createJob'))))),
        React.createElement(Modal, { open: isUploading, size: 'small' },
            React.createElement(Modal.Content, null,
                React.createElement(InfoMessage, null,
                    t('jobUpload.uploading'),
                    ":",
                    ' ',
                    uploadedJobs,
                    "/",
                    languagesTo.length),
                React.createElement("span", null, uploadedJobs === languagesTo.length ? React.createElement(Icon, { name: 'check circle', color: 'green', size: "big" }) : React.createElement(Loading, { active: true, inline: true })))),
        React.createElement(Modal, { open: isExternalDateIncorrect, size: 'small' },
            React.createElement(Modal.Content, null,
                React.createElement("p", null,
                    " ",
                    React.createElement(Icon, { name: 'warning sign', color: "yellow" }),
                    t('jobUpload.incorrectDeadline.description'))),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { basic: true, color: 'red', onClick: () => { setIsExternalDateIncorrect(false); setActivePhase(0); } },
                    React.createElement(Icon, { name: 'remove' }),
                    " ",
                    t('jobUpload.incorrectDeadline.uploadAgain')),
                React.createElement(Button, { color: 'green', onClick: () => { setIsExternalDateIncorrect(false); setExternalDeadline(() => null); createNewJob(null); } },
                    React.createElement(Icon, { name: 'checkmark' }),
                    " ",
                    t('jobUpload.incorrectDeadline.exfluencyDeadline')))),
        React.createElement(Modal, { open: isLanguageMissmatch, size: 'small' },
            React.createElement(Modal.Content, null,
                React.createElement("p", null,
                    " ",
                    React.createElement(Icon, { name: 'warning sign', color: "yellow" }),
                    " ",
                    t('jobUpload.languageMismatch'),
                    " ")),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { basic: true, color: 'red', onClick: () => { setIsLanguageMissmatch(false); } },
                    React.createElement(Icon, { name: 'remove' }),
                    " ",
                    t('jobUpload.doubleCheck')),
                React.createElement(Button, { color: 'green', onClick: () => { setIsLanguageMissmatch(false); createNewJob(externalDeadline, true); } },
                    React.createElement(Icon, { name: 'checkmark' }),
                    " ",
                    t('jobUpload.translateAnyway')))));
};
export default NewUpload;
